import { db } from "../firebase"

async function getArchive(archiveLimit) {
  let query = db
    .collection("archive")
    .orderBy("dueDate", "desc")
    .limit(archiveLimit)

  let snapshot = await query.get()
  let ids = []
  let data = []
  snapshot.forEach(doc => {
    ids.push(doc.id)
    data.push(doc.data())
  })
  return Promise.resolve({
    ids: ids,
    data: data,
    limit: snapshot.size
  })
}

export default getArchive
