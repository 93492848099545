import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Container, Row } from "react-bootstrap";

export default function VirtualTabs(props) {
  const handleChange = (event, newValue) => {
    props.setTab(newValue);
  };

  let tabs = [];

  for (let i = 0; i < props.tabs.length; i++) {
    tabs.push(<Tab label={props.tabs[i]} />);
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Tabs
          value={props.currTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          variant="scrollable"
        >
          {tabs}
        </Tabs>
      </Row>
      {props.currTab === 0 && (
        <Row
          className="justify-content-center"
          style={{
            marginTop: "20px",
            paddingLeft: "25px",
            paddingRight: "25px",
            textAlign: "center",
          }}
        >
          <i style={{ maxWidth: "500px" }}>
            These are projects where a teacher has not yet selected a student to
            work with. Students volunteers may sign up for these projects.
          </i>
        </Row>
      )}
      {props.currTab === 1 && (
        <Row
          className="justify-content-center"
          style={{
            marginTop: "20px",
            paddingLeft: "25px",
            paddingRight: "25px",
            textAlign: "center",
          }}
        >
          <i style={{ maxWidth: "500px" }}>
            These are projects where a teacher has selected a student to work
            with. Students are actively working on these projects.
          </i>
        </Row>
      )}
      {props.currTab === 2 && (
        <Row
          className="justify-content-center"
          style={{
            marginTop: "20px",
            paddingLeft: "25px",
            paddingRight: "25px",
            textAlign: "center",
          }}
        >
          <i style={{ maxWidth: "500px" }}>
            These are projects highlighted by the RemoteTA leadership team. All
            projects in this category are unique and - as always - were matched
            with excellent student work.
          </i>
        </Row>
      )}
    </Container>
  );
}
