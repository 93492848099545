import React from "react"
import { auth } from "../firebase"
import Layout from "../components/utils/layout"
import Loader from "../components/utils/Loader"
import SEO from "../components/utils/seo"
import TitleLine from "../components/utils/TitleLine"
import getUserData from "../scripts/getUserData"
import getViewableProjects from "../scripts/getViewableProjects"
import "../styles/createStudentAccountPage.css"
import { Container, Row } from "react-bootstrap"
import getArchive from "../scripts/getArchive"
import UniversalManager from "../components/cards/UniversalManager"
import VirtualTabs from "../components/utils/VirtualTabs.js"
import NewAsyncButton from "../components/utils/NewAsyncButton"
import BackgroundImage from "../components/utils/BackgroundImage"
import dots from "../images/bgs/dots.svg"
class ViewTasks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: null,
      loaded: false,
      initialized: false,
      userType: "",
      pendingInfo: [],
      pendingIDs: [],
      activeInfo: [],
      activeIDs: [],
      archiveIDs: [],
      archiveInfo: [],
      learnMore: false,
      index: -1,
      activeTab: 0,
      userData: null,
      pendingLimit: 10,
      activeLimit: 10,
      archiveLimit: 10,
      showLoadMoreActive: true,
      showLoadMorePending: true,
      showLoadMoreArchive: true,
      showLoadMoreGeneral: true,
    }
    this.init = this.init.bind(this)
    this.learnMoreCallback = this.learnMoreCallback.bind(this)
    this.refresh = this.refresh.bind(this)
    this.signUpCallback = this.signUpCallback.bind(this)
  }

  learnMoreCallback(index) {
    this.setState({
      showLoadMoreGeneral: index === -1,
      learnMore: !this.state.learnMore,
      index: index,
    })
  }

  signUpCallback(index) {
    let newTaskInfo = this.state.pendingInfo
    let newTaskIDs = this.state.pendingIDs
    newTaskIDs.splice(index, 1)
    newTaskInfo.splice(index, 1)
    this.setState({ pendingInfo: newTaskInfo, pendingIDs: newTaskIDs })
  }

  refresh() {
    this.setState({ learnMore: false })
  }

  init() {
    if (this.state.authenticated !== null) {
      this.setState({ initialized: true })
      let user = auth.currentUser
      let user_id = user && user.emailVerified ? user.uid : ""
      if (user && user.emailVerified) {
        getUserData(user.uid)
          .then(data => {
            if (data.userType === "student" && !data.joinedSlack) {
              this.setState({ userType: "guest" })
            } else {
              this.setState({ userType: data["userType"] })
            }
            this.setState({ userID: user_id, userData: data })
            this.getProjects(
              user_id,
              data,
              this.state.pendingLimit,
              this.state.activeLimit,
              this.state.archiveLimit
            )
          })
          .catch(error => {
            console.log(error)
            //TODO ERROR HANDLE
          })
      } else {
        this.setState({ userID: user_id, userType: "guest", userData: {} })
        this.getProjects(
          user_id,
          {},
          this.state.pendingLimit,
          this.state.activeLimit,
          this.state.archiveLimit
        )
      }
    }
  }

  getProjects = (
    user_id,
    userData,
    pendingLimit,
    activeLimit,
    archiveLimit
  ) => {
    let promises = []
    promises.push(
      getViewableProjects(user_id, userData, pendingLimit, activeLimit)
        .then(projectData => {
          this.setState({
            pendingInfo: projectData.pending.data,
            pendingIDs: projectData.pending.ids,
            activeIDs: projectData.active.ids,
            activeInfo: projectData.active.data,
            activeTab: !this.state.loaded
              ? projectData.pending.ids.length <= 0
                ? 1
                : 0
              : this.state.activeTab,
            loaded: true,
            showLoadMoreActive: projectData.activeLimit % 10 === 0,
            showLoadMorePending: projectData.pendingLimit % 10 === 0,
          })
        })
        .catch(error => {
          console.log(error)
          //TODO Error handle
        })
    )
    promises.push(
      getArchive(archiveLimit)
        .then(projectData2 => {
          this.setState({
            archiveInfo: projectData2.data,
            archiveIDs: projectData2.ids,
            showLoadMoreArchive: projectData2.limit % 10 === 0,
          })
        })
        .catch(error => {
          console.log(error)
          //TODO Error handle
        })
    )
    return Promise.all(promises).then(() => {
      this.setState({ loaded: true })
    })
  }

  loadMoreProjects = (reset, tab) => {
    let {
      pendingLimit,
      activeLimit,
      archiveLimit,
      userID,
      userData,
    } = this.state
    if (tab === "pending") {
      pendingLimit += 10
    } else if (tab === "active") {
      activeLimit += 10
    } else if (tab === "archive") {
      archiveLimit += 10
    }
    this.getProjects(userID, userData, pendingLimit, activeLimit, archiveLimit)
      .then(() => {
        reset("Load More")
      })
      .catch(error => {
        console.log(error)
      })
    this.setState({ pendingLimit, activeLimit, archiveLimit })
  }

  setTab = tab => {
    this.setState({ activeTab: tab, index: -1, learnMore: false })
  }

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (!user) {
        this.setState({ authenticated: false })
      } else {
        this.setState({ authenticated: true })
      }
    })
  }

  render() {
    if (!this.state.initialized) {
      this.init()
    }
    let noPending =
      this.state.pendingInfo.length <= 0 && this.state.userType === "guest"

    let tabToRender = <></>
    let activeTabNum = this.state.activeTab
    if (noPending) {
      activeTabNum++
    }
    if (activeTabNum === 0) {
      tabToRender = (
        <Container
          className="justify-content-center"
          style={{ marginTop: "50px" }}
        >
          <Row className="justify-content-center">
            {this.state.pendingInfo.length > 0 ? (
              <UniversalManager
                refresh={this.refresh}
                taskInfo={this.state.pendingInfo}
                taskIDs={this.state.pendingIDs}
                modules={this.state.userType === "student" ? ["Sign Up"] : []}
                nameAndSchool={["teacher", "teacher"]}
                contact=""
                signUpCallback={this.signUpCallback}
                userData={this.state.userData}
                learnMoreCallback={this.learnMoreCallback}
                learnMore={this.state.learnMore}
                showDates={false}
              />
            ) : (
              <h2 className="noVolunteerOpportunities">
                {" "}
                All projects have been taken! Check out the Active Projects tab
                to see what our volunteers are up to!{" "}
              </h2>
            )}
          </Row>
          {this.state.showLoadMorePending &&
            this.state.pendingInfo.length > 0 &&
            this.state.showLoadMoreGeneral && (
              <Row className="justify-content-center">
                <NewAsyncButton
                  buttonTexts={[
                    "Load More",
                    "Loading...",
                    "Error. Try Again?",
                    "Load More",
                  ]}
                  buttonColor={["", "", "", ""]}
                  buttonVariants={["primary", "warning", "danger", "success"]}
                  callback={reset => this.loadMoreProjects(reset, "pending")}
                ></NewAsyncButton>
              </Row>
            )}
        </Container>
      )
    } else if (activeTabNum === 1) {
      tabToRender = (
        <Container
          className="justify-content-center"
          style={{ marginTop: "50px" }}
        >
          <Row className="justify-content-center">
            {this.state.activeInfo.length > 0 ? (
              <UniversalManager
                refresh={this.refresh}
                taskInfo={this.state.activeInfo}
                taskIDs={this.state.activeIDs}
                modules={[]}
                nameAndSchool={["teacher", "teacher"]}
                contact=""
                userData={this.state.userData}
                learnMoreCallback={this.learnMoreCallback}
                learnMore={this.state.learnMore}
                showDates={false}
              />
            ) : (
              <h2 className="noVolunteerOpportunities">
                {" "}
                All projects have been completed! Check out the Highlighted
                Projects tab to see our volunteers' work!{" "}
              </h2>
            )}
          </Row>
          {this.state.showLoadMoreActive &&
            this.state.activeInfo.length > 0 &&
            this.state.showLoadMoreGeneral && (
              <Row className="justify-content-center">
                <NewAsyncButton
                  buttonTexts={[
                    "Load More",
                    "Loading...",
                    "Error. Try Again?",
                    "Load More",
                  ]}
                  buttonColor={["", "", "", ""]}
                  buttonVariants={["primary", "warning", "danger", "success"]}
                  callback={reset => this.loadMoreProjects(reset, "active")}
                ></NewAsyncButton>
              </Row>
            )}
        </Container>
      )
    } else {
      tabToRender = (
        <Container
          className="justify-content-center"
          style={{ marginTop: "50px" }}
        >
          <Row className="justify-content-center">
            {this.state.archiveInfo.length > 0 ? (
              <UniversalManager
                refresh={this.refresh}
                taskInfo={this.state.archiveInfo}
                taskIDs={this.state.archiveIDs}
                modules={[]}
                nameAndSchool={["teacher", "teacher"]}
                contact=""
                userData={this.state.userData}
                learnMoreCallback={this.learnMoreCallback}
                learnMore={this.state.learnMore}
                showDates={false}
              />
            ) : (
              <h2 className="noVolunteerOpportunities"> </h2>
            )}
          </Row>
          {this.state.showLoadMoreArchive &&
            this.state.archiveInfo.length > 0 &&
            this.state.showLoadMoreGeneral && (
              <Row className="justify-content-center">
                <NewAsyncButton
                  buttonTexts={[
                    "Load More",
                    "Loading...",
                    "Error. Try Again?",
                    "Load More",
                  ]}
                  buttonColor={["", "", "", ""]}
                  buttonVariants={["primary", "warning", "danger", "success"]}
                  callback={reset => this.loadMoreProjects(reset, "archive")}
                ></NewAsyncButton>
              </Row>
            )}
        </Container>
      )
    }
    let full = false
    if (activeTabNum === 0) {
      full = this.state.pendingIDs.length <= 2
    } else if (activeTabNum === 1) {
      full = this.state.activeIDs.length <= 2
    } else {
      full = this.state.archiveIDs.legnth <= 2
    }

    if (this.state.loaded) {
      return (
        <div class="createStudentAccountPage">
          <Layout image={dots} full={full}>
            <SEO title="Browse Tasks" />
            <Container className="tbMargins">
              <TitleLine
                title="Available Volunteer Opportunities"
                description="Browse Opportunities, Select, and Apply"
              />
            </Container>
            <Container className="tabalign">
              <Row
                className="justify-content-center"
                style={{ marginTop: "80px" }}
              >
                <VirtualTabs
                  setTab={this.setTab}
                  currTab={this.state.activeTab}
                  tabs={
                    noPending
                      ? ["Active Projects", "Highlighted Projects"]
                      : [
                          "Available Projects",
                          "Active Projects",
                          "Highlighted Projects",
                        ]
                  }
                />
              </Row>
              {tabToRender}
            </Container>
          </Layout>
        </div>
      )
    } else {
      return (
        <Layout full>
          <Loader />
        </Layout>
      )
    }
  }
}

export default ViewTasks
