import { db, default as firebase } from "../firebase"

async function getViewableProjects(
  userID,
  userData,
  pendingLimit,
  activeLimit
) {
  let pendingProjectsQuery = db
    .collection("projects")
    .where("postedDate", "!=", 0)
    .where("status", "==", "pending")
    .orderBy("postedDate", "asc")
    .limit(pendingLimit)

  let activeProjectsQuery = db
    .collection("projects")
    .where("dueDate", "!=", 0)
    .where("status", "in", ["active", "review", "hoursgranted", "studentClose", "teacherClose"])
    .orderBy("dueDate", "desc")
    .limit(activeLimit)

  let pendingProjects = await pendingProjectsQuery.get()
  let activeProjects = await activeProjectsQuery.get()

  let pendingIDs = []
  let pendingData = []
  let activeIDs = []
  let activeData = []

  pendingProjects.forEach(doc => {
    if (userID === "" || !userData.pendingProjects.includes(doc.id)) {
      pendingIDs.push(doc.id)
      pendingData.push(doc.data())
    }
  })
  activeProjects.forEach(doc => {
    if (
      userID === "" ||
      (!userData.activeProjects.includes(doc.id) &&
        !userData.reviewProjects.includes(doc.id))
    ) {
      activeIDs.push(doc.id)
      activeData.push(doc.data())
    }
  })

  return Promise.resolve({
    pending: { ids: pendingIDs, data: pendingData },
    active: { ids: activeIDs, data: activeData },
    pendingLimit: pendingProjects.size,
    activeLimit: activeProjects.size
  })
}

export default getViewableProjects
